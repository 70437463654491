@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Comfortaa:400');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400');

// Set your brand colors
$purple: #4d8a77;
$pink: #874827;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

// Update Bulma's global variables
$family-sans-serif: "Montserrat", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import only what you need from Bulma
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/title.sass";
@import "~bulma/sass/elements/box.sass";
@import "~bulma/sass/form/_all.sass";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/components/menu.sass";
@import "~bulma/sass/layout/hero.sass";
@import "~bulma/sass/layout/section.sass";
@import "~bulma/sass/grid/tiles";
@import "~bulma/sass/grid/columns";
@import "~bulma/sass/components/tabs";
@import "~bulma/sass/helpers/spacing";
@import "~bulma/sass/helpers/flexbox";
@import "~bulma/sass/helpers/visibility";
@import "~bulma/sass/helpers/typography";
@import "~bulma/sass/components/card";

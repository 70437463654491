$pswp__assets-path: "~photoswipe/src/css/default-skin/";

@import "style";
@import '~perfundo';

$perfundo-background-color: rgba(#000, 0.9);
$perfundo-color: #fff;
$perfundo-control-use-icons: false;
$perfundo-html-padding: 2em;
$perfundo-html-max-width: 42em;
$perfundo-html-background-color: #fff;

$section-subtitle-color: #56493f;


$high-color: #a00000;
$high-bg: #a0000033;
$low-color: #006400;
$low-bg: #d8f3b7;
$medium-color: #b36400;
$medium-bg: #ffd70061;
$disabled-color: #d5d5d5;
$disabled-border: 1px #d5d5d5 dashed;
$disabled-bg: #fff;

html {
  scroll-behavior: smooth;
}

.navbar {
  background: #1117;
  position: fixed;
  top: 0;
  transition: 0.3s;
  width: 100%;

  &.hidden {
    top: -52px;
  }

  .navbar-item {
    @media screen and (min-width: 1024px) {
      color: #f8f8f8;
    }
  }

  .navbar-burger {
    color: #fff;

    span {
      height: 2px;
    }
  }
}

.hero.first {
  .container {
    @media screen and (max-width: 1023px) {
      padding-top: calc(3rem + 52px) !important;
    }
  }
}

.title,
.subtitle {
  font-family: "Comfortaa";
}

section {
  .subtitle {
    color: $section-subtitle-color;
    margin-bottom: 7rem !important;

    @media screen and (max-width: 1023px) {
      margin-bottom: 3rem !important;
    }
  }
}

.navbar.floating {
  position: fixed;
  width: 100%;
  background: $beige-lighter;
}

.inner-body {
  background-image: url("assets/images/head-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  width: 100%;

  @media screen and (min-width: 769px) {
    padding-top: 5%;
  }

  .hazelnut {
    @media screen and (min-width: 769px) {
      width: 2.5rem;
    }

    @media screen and (max-width: 768px) {
      width: 1.5rem;
    }

  }
}

.gite-desc {
  background: rgb(241, 195, 133);
  background: linear-gradient(0deg, rgba(241, 195, 133, 1) 0%, rgba(239, 240, 235, 1) 100%);

  .box {
    transition: all .5s ease;

    &.anim-left:hover {
      transform: translate(-8px, -6px) rotate(-1deg) scale(1.05);
    }

    &.anim-right:hover {
      transform: translate(6px, 8px) rotate(1deg) scale(1.05);
    }

    &.anim-up:hover {
      transform: translateY(-8px) scale(1.05);
    }

    &.anim-down:hover {
      transform: translateY(8px) scale(1.05);
    }

  }
}

.activite-inner {
  align-items: center;

  .column:last-child {
    padding-left: 0;
    margin-left: -22px;

    img {
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
  }

  .column:first-child {
    padding: 30px 20px 5px 12px;
  }

  .activite-title {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .activite-item-ctr {
    display: flex;
    justify-content: space-around;
    font-size: 1.8rem;
    padding-top: 16px;
  }

  .card-content .subtitle {
    margin-bottom: 0 !important;
  }

  .credit {
    font-size: .7rem;

    &.mobile {
      bottom: 30px;
      background: #fffa;
      padding: 0 5px;
      color: #000;
      position: relative;
    }
  }

  .card {
    margin-bottom: 2em;
  }
}

.activite:last-child {
  .card {
    margin-bottom: 0;
  }
}

.activite:nth-child(even) {
  .activite-inner {
    flex-direction: row-reverse;

    .column:last-child {
      padding-right: 0;
      margin-right: -40px;

      img {
        border-radius: 70% 30% 30% 70% / 30% 30% 70% 70%;
      }
    }
  }
}

#photos {
  background: linear-gradient(180deg, #3d79ff40 0%, #3d79ff40 50%, rgba(255, 168, 61, 0.25) 100%);

  .perfundo__image {
    background-repeat: no-repeat;
    width: 800px;
    padding-top: 66.25%;
  }

  .hero-body {
    flex-direction: column;

    .container {
      padding-bottom: 3em;

      p {
        margin-bottom: 3rem;
      }
    }
  }

  ion-icon {
    --color: #fff;
    height: 32px;
    width: 32px;
  }

  .img-thumb {
    @media screen and (min-width: 769px) {
      max-height: 150px;
    }
  }
}

#calendar {

  input[name=bamboo] {
    transform: translate(-10000px, -10000px);
  }

  button.button.is-error::after {
    content: 'Une erreur est survenue. \A Merci de vérifier les informations saises et de réessayer';
    position: absolute;
    top: 50px;
    padding: 5px 10px;
    background: #e8252538;
    color: #7d0000;
    font-size: 13px;
    border-radius: 6px;
    width: 300px;
    white-space: pre-wrap;
  }

  .contact-container {
    flex: 1;
    width: 100%;
    justify-content: center;

    @media screen and (min-width: 1217px) {
      padding: 0 3%;
    }

    @media screen and (max-width: 768px) {
      .columns {
        margin: -75rem 0 !important;

        .column {
          padding: 12px 0 !important;
        }
      }
    }

    .column {
      display: flex;
      justify-content: center;

      &.contact-ctr {
        flex-direction: column;
        max-width: 595px;
      }

      &.cal-ctr {
        flex-direction: column;
        align-items: center
      }
    }
  }

  .calendar {
    position: relative;
    max-width: 595px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;


    @media screen and (max-width: 575px) {
      max-width: 390px;
    }
  }

  .hero-body {
    flex-direction: column;
    flex-grow: 0;

    .container {
      padding-bottom: 3em;
    }
  }

  form {
    width: 100%;
  }

  .calendar__picture {
    position: relative;
    padding: 20px;
    color: #0d0d13;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 1.8rem;
      text-transform: capitalize;
    }

    .btn {
      font-size: 1.8rem;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: 3rem;
      text-align: center;

      &:hover {
        box-shadow: 0 0 4px 0px #0002;
        cursor: pointer;
      }

    }
  }

  .calendar__date {
    padding: 40px;
    display: grid;
    grid-template-columns: repeat(7, minmax(25px, 1fr));
    grid-gap: 40px;
    box-sizing: border-box;

    @media screen and (max-width: 575px) {
      column-gap: 13px;
    }
  }

  .legend-ctr {
    display: flex;
    flex-wrap: wrap;

    .legend-cal {
      border-radius: 20px;
      padding: 8px 14px;
      font-size: .8rem;
      margin: 10px 10px 0 0;

      &.high {
        color: $high-color;
        background: $high-bg;
      }

      &.low {
        color: $low-color;
        background: $low-bg;
      }

      &.medium {
        color: $medium-color;
        background: $medium-bg;
      }

      &.disabled {
        color: $disabled-color;
        border: $disabled-border;
        background: $disabled-bg;
      }
    }
  }

  .calendar__day {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-weight: 600;
    color: #262626;
  }

  .calendar__number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    padding: 20px;
    color: #262626;
    border-radius: 50%;

    @media screen and (max-width: 575px) {
      padding: 16px;
      font-size: .8em;
    }

    &--disabled {
      color: $disabled-color !important;
      border: $disabled-border;
    }

    &--low {
      background: $low-bg;
      color: $low-color !important;
      font-weight: 600;
    }

    &--high {
      background: $high-bg;
      color: $high-color !important;
      font-weight: 600;
    }

    &--medium {
      color: $medium-color !important;
      background: $medium-bg;
      font-weight: 600;
    }

    &--current {
      background-color: #009688;
      color: #fff !important;
      border: none !important;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .pricing-ctr {

    .pricing-sub {
      font-weight: 600;
      font-size: 1.4rem;
    }

    .pricing-list {
      padding-bottom: 20px;
    }
  }

  .info {
    width: 100%;
    max-width: 595px;
  }

  .info, .form-title {
    font-size: 1.3rem;
    padding-bottom: 20px;
  }
}
